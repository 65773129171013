import axios from "axios";
import store from "@/store";
import { MessageBox } from "element-ui";
import { COMMON_MESSAGE } from "@/constants/";
import { comLoginOut } from "@/utils/common";
import { gatewayError, showMessage } from "./status";

// <-------------------------- 请求封装 -------------------------------->

/**
 * patch封装
 */
export function patch(api, data = {}, method = "PATCH", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * put封装
 */
export function put(api, data = {}, method = "PUT", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * post封装
 */
export function post(api, data = {}, method = "POST", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * get封装
 */
export function get(api, data = {}, method = "GET", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

/**
 * delete封装
 */
export function del(api, data = {}, method = "DELETE", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

export function delBody(api, data = {}, method = "DEL_BODY", loading = true) {
  return request(api, data, method, getHeader(), loading);
}

// 上传文件
export function upload(api, data = {}, method = "POST", loading = true) {
  return request(api, data, method, getHeader("multipart/form-data"), loading);
}

// 添加响应拦截器
// 1.网络错误处理r
// 2.授权错误处理
// 3.普通错误处理
// 4.代码异常处理
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("error", error);
    const { response } = error || {};
    const { status } = response || {};
    gatewayError(status);
    return null;
  }
);

export function getHeader(ContentType = "application/json") {
  let header = {};
  header = {
    "Content-Type": ContentType || "application/json",
    Authorization: store.getters.userInfo.authorization,
  };
  return header;
}

let IS_LOGIN_EXPIRES = false;

/**
 * 统一的请求封装，
 *
 * @param {String} api 请求的api地址
 * @param {JSON} params 请求参数或者请求体，具体参考文档
 * @param {String} methods 请求方式，默认POST/GET
 * @param {JSON} header 请求的头部
 * @param {boolean} [loading=true] 是否显示loading，默认true
 */
export function request(api, params = {}, method, header, loading = true) {
  return new Promise((resolve, reject) => {
    let data =
      method.toLocaleLowerCase() === "get" ||
      method.toLocaleLowerCase() === "delete"
        ? "params"
        : "data";

    method = method == "DEL_BODY" ? "DELETE" : method;

    const baseUrl = store.getters.deviceInfo.baseUrl
      ? store.getters.deviceInfo.baseUrl
      : `${location.protocol}//${process.env.VUE_APP_BASE_URL}`;
    const fullUrl = /^https?/.test(api) ? api : baseUrl + api;

    // 请求
    axios({
      url: fullUrl,
      [data]: params,
      method: method,
      headers: header,
    })
      .then((result) => {
        const {
          data: { code, msg, message, data },
        } = result;
        if (code === 200 || message == "SUCCESS") {
          resolve(data);
        } else {
          // 用户锁定、踢下线、未注册
          if ([703, 704, 705, 706, 707].includes(code)) {
            loginErrorFunc(msg, code);
          } else {
            showMessage(msg || message, "error");
            reject(result);
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}

const loginErrorFunc = (msg, code) => {
  if (IS_LOGIN_EXPIRES) {
    return;
  }
  IS_LOGIN_EXPIRES = true;
  if([703, 704].includes(code)) {
    msg = COMMON_MESSAGE.loginExpires;
  }
  MessageBox.alert(msg, "提示", {
    confirmButtonText: "确定",
    type: "warning",
    showClose: false,
    center: true,
    dangerouslyUseHTMLString: true,
    callback: (action) => {
      comLoginOut(code);
      IS_LOGIN_EXPIRES = false;
    },
  });
  return;
};

export const downLoadPDF = (filename, url) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: getHeader(),
      method: "get",
      url: url, // 请求地址
      responseType: "blob", // 表明返回服务器返回的数据类型
    }).then(
      (response) => {
        resolve(response.data);
        let blob = new Blob([response.data], {
          type: "application/pdf",
        });
        let fileName = filename;
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, fileName);
        } else {
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          //释放内存
          window.URL.revokeObjectURL(link.href);
        }
      },
      (err) => {
        reject(err);
      }
    );
  });
};

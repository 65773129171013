export default {
  setUserInfo(state, payload) {
    state.userInfo = Object.assign({}, state.userInfo, payload);
  },
  updateUserInfo(state, userInfo) {
    const old = state.userInfo;
    state.userInfo = { ...old, ...userInfo };
    sessionStorage.setItem("userInfo", JSON.stringify(state.userInfo));
  },
  setDeviceInfo(state, payload) {
    state.deviceInfo = Object.assign({}, state.deviceInfo, payload);
  },
  setAsideMenu(state, payload) {
    state.asideMenus = Object.assign({}, state.asideMenus, payload);
  },
  setGasInfo(state, payload) {
    state.gasInfo = Object.assign({}, state.gasInfo, payload);
  },
  setQueryInfo(state, payload) {
    sessionStorage.setItem("queryInfo", JSON.stringify(payload));
    return Object.assign(state.queryInfo, payload);
  }
};

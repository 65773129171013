export default {
  deviceInfo(state) {
    return state.deviceInfo || {};
  },
  userInfo(state) {
    return state.userInfo ||{};
  },
  asideMenus(state) {
    return state.asideMenus || {};
  },
  gasInfo(state) {
    return state.gasInfo || {};
  },
  queryInfo(state) {
    return state.queryInfo || {};
  }
}
import { resultStatusMap } from "@/constants/";

export default {
  overview: {
    lastList: [
      {
        label: "出块时间",
        prop: "timestamp",
        type: "date",
      },
      {
        label: "区块高度",
        prop: "blockNumber",
      },
      {
        label: "出块方",
        prop: "nodeName;minerHash",
        type: "multProp"
      },
      {
        label: "交易hash",
        prop: "hash",
      },
      {
        label: "发送方",
        prop: "from",
      },
      {
        label: "接收方",
        prop: "to",
      },
    ],
  },
  orderMan: {
    orderList: [
      {
        label: "订单发起时间",
        prop: "createdTime",
        type: "date",
      },
      {
        label: "订单编号",
        prop: "orderId",
      },
      {
        label: "支付金额",
        prop: "amount",
        type: "money",
      },
      {
        label: "支付状态",
        prop: "status",
        type: "status",
        statusMap: resultStatusMap,
      },
      {
        label: "支付时间",
        prop: "payTime",
        type: "date",
      },
      {
        label: "支付编号",
        prop: "bizId",
      },
      {
        label: "支付银行编号",
        prop: "bankId",
      },
      {
        label: "支付银行卡号",
        prop: "bankCardNo",
      },
    ],
  },
  wallet: {
    detail: [
      {
        label: "时间",
        prop: "createdTime",
        type: "date",
      },
      {
        label: "燃料消耗",
        prop: "amount",
        type: "signNumber"
      },
      {
        label: "内容",
        prop: "interfaceTypeDesc",
      },
      {
        label: "燃料剩余",
        prop: "usableGas",
        type: 'money',
      },
    ],
  },
  configs: {
    ipconfig: [
      {
        label: "序号",
        prop: "no"
      },
      {
        label: "服务器/网关地址",
        prop: "ip",
      },
      {
        label: "添加时间",
        prop: "addTime",
        type: "date",
      },
    ]
  }
};

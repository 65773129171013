// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./utils/flexible";
import { COMMON_MESSAGE, LOADING_OPTIONS } from "@/constants/";
import table from "@/components/baseTable/table";
import tableHeader from "@/utils/tableHeader";
import VueClipboard from "vue-clipboard2";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI, { size: "small", zIndex: 3000 });
Vue.use(table);
Vue.use(VueClipboard);

Vue.config.productionTip = false;

Vue.prototype.$CommonMsg = COMMON_MESSAGE;
Vue.prototype.$loadingOptions = LOADING_OPTIONS;
Vue.prototype.$tableHeader = tableHeader;

Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === "watchStorage") {
    var newStorageEvent = document.createEvent("StorageEvent");
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        );
        window.dispatchEvent(newStorageEvent);
      },
    };
    return storage.setItem(key, newVal);
  }
};
router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  next();
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import store from "@/store";
import { SINGLE_MENUS, MULT_MENUS, NO_LOGIN_ROUTENAME } from "@/constants";

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/views/Layout.vue"),
    meta: {
      title: "数字资产服务平台",
    },
    children: [
      {
        name: "Index",
        path: "/index",
        meta: { tabName: "Index" },
        component: () => import("@/views/Index.vue"),
      },
      {
        name: "Account",
        path: "/account",
        meta: { tabName: "Account" },
        component: () => import("@/views/Account.vue"),
      },
      {
        name: "ModifyPassword",
        path: "/modify_password",
        meta: { tabName: "ModifyPassword" },
        component: () => import("@/views/ModifyPassword.vue"),
      },
      {
        name: "RealnameAuth",
        path: "/realname-auth",
        meta: { tabName: "Account" },
        component: () => import("@/views/RealnameAuth.vue"),
      },
      {
        name: "EnterpriseAuth",
        path: "/enterprise-auth",
        meta: { tabName: "Account" },
        component: () => import("@/views/EnterpriseAuth.vue"),
      },
      {
        name: "OrderMan",
        path: "/order-man",
        meta: { tabName: "OrderMan" },
        component: () => import("@/views/OrderMan.vue"),
      },
      {
        name: "Question",
        path: "/question",
        meta: { tabName: "Question" },
        component: () => import("@/views/Question.vue"),
      },
      {
        name: "IntegratedQuery",
        path: "/integrated-query",
        meta: { tabName: "IntegratedQuery" },
        component: () => import("@/views/IntegratedQuery.vue"),
      },
      {
        name: "IntegratedQueryResult",
        path: "/integrated-query-result",
        meta: { tabName: "IntegratedQuery" },
        component: () => import("@/views/IntegratedQueryResult.vue"),
      },
      {
        name: "ServiceOverview",
        path: "/service-overview",
        meta: { tabName: "ServiceOverview" },
        component: () => import("@/views/ServiceOverview.vue"),
      },
      {
        name: "FuelManage",
        path: "/fuel-manage",
        meta: { tabName: "FuelManage" },
        component: () => import("@/views/FuelManage.vue"),
      },
      {
        name: "Recharge",
        path: "/recharge",
        meta: { tabName: "FuelManage" },
        component: () => import("@/views/Recharge.vue"),
      },
      {
        name: "ReportProblem",
        path: "/report-problem",
        meta: { tabName: "ReportProblem" },
        component: () => import("@/views/ReportProblem.vue"),
      },
    ],
  },
  {
    name: "Login",
    path: "/login",
    meta: { tabName: "Login", noHeader: true, noFoot: true, isLogin: true },
    component: () => import("@/views/Login.vue"),
  },
  {
    name: "Register",
    path: "/register",
    meta: { tabName: "Register", noHeader: true, noFoot: true, isLogin: true },
    component: () => import("@/views/Register.vue"),
  },
  {
    name: "ResetPassword",
    path: "/reset-password",
    meta: {
      tabName: "ResetPassword",
      noHeader: true,
      noFoot: true,
      isLogin: true,
    },
    component: () => import("@/views/ResetPassword.vue"),
  },
  {
    name: "Agreement",
    path: "/agreement",
    meta: {
      tabName: "Agreement",
      noHeader: false,
      noFoot: true,
      isLogin: true,
    },
    component: () => import("@/views/Agreement.vue"),
  },
];

const router = new Router({
  mode: "history",
  routes,
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  if (isChunkLoadFailed) {
    location.reload();
  }
});

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if (to.meta.content && to.meta.content.length > 0) {
    const head = document.getElementsByTagName("head");
    for (let i = 0; i < to.meta.content.length; i++) {
      const meta = document.createElement("meta");
      meta.name = to.meta.content[i].name;
      meta.content = to.meta.content[i].content;
      head[0].appendChild(meta);
    }
  }
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = `${title}`;
  }

  const userInfo = sessionStorage.getItem("userInfo") || "{}";
  const userMap = JSON.parse(userInfo);
  if (
    !userMap.authorization &&
    !NO_LOGIN_ROUTENAME.includes(to.name)
  ) {
    next({ name: "Login" });
  } else {
    const chargeModel =
    userMap.shop && userMap.shop.chargeModel;
    if (userMap.phone && chargeModel == 2 && to.name == 'FuelManage') {
      next({ name: "Index" });
    }else {
      store.commit("setAsideMenu", {});
      next();
    }
  }
});

router.afterEach((to, from, next) => {
  const tabName = to.meta.tabName;
  let isSingleMenu = false;
  Object.keys(SINGLE_MENUS).forEach((item) => {
    Object.keys(SINGLE_MENUS[item]).forEach((index) => {
      if (SINGLE_MENUS[item][index] == tabName) {
        isSingleMenu = true;
        store.commit("setAsideMenu", {
          curSingleIndex: index,
          menuType: item,
          singleMenu: isSingleMenu,
        });
      }
    });
  });
  if (!isSingleMenu) {
    Object.keys(MULT_MENUS).forEach((item) => {
      if (MULT_MENUS[item] == tabName) {
        store.commit("setAsideMenu", {
          curMultIndex: item,
          singleMenu: isSingleMenu,
        });
      }
    });
  }
});

export default router;

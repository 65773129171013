import { message } from "@/utils/resetMessage";

const STATUS_CODE = {
  400: "请求错误(400)",
  401: "未授权，请重新登录(401)",
  403: "拒绝访问(403)",
  404: "请求出错(404)",
  408: "请求超时(408)",
  500: "服务器错误(500)",
  501: "服务未实现(501)",
  502: "网络错误(502)",
  503: "服务不可用(503)",
  504: "网络超时(504)",
  505: "HTTP版本不受支持(505)",
};

// 网关报错
export const gatewayError = (status) => {
  let msg = STATUS_CODE[status];
  if (!msg) {
    msg = status ? `请求错误(${status})` : "请求出错，请检查网络或联系管理员！";
  }
  message({
    message: msg,
    type: "error",
  });
};

// 网络请求状态码
export const showMessage = (status, type) => {
  let msg = STATUS_CODE[status];
  let msgType = "";
  if (msg) {
    msgType = "error";
  } else {
    if (!msg && status) {
      msg = status;
    } else {
      msg = "请求出错，请检查网络或联系管理员！";
    }
    if (type === "error") {
      msgType = "error";
    } else {
      msgType = "info";
    }
  }

  message({
    message: msg,
    type: msgType,
  });

  return msg;
};

export const NAV_LIST = [
  {
    title: "首页",
    pathName: "Index",
    path: "index",
  },
];

export const COMMON_MESSAGE = {
  networkErr: "网络异常，请稍后重试~",
  loadingTxt: "加载中...",
  building: "我们正在加急进行服务升级，会尽快与您见面",
  loginExpires: "登录态失效，请重新登录",
};

export const LOADING_OPTIONS = {
  lock: true,
  text: "",
  spinner: "none",
  customClass: "ls-loading-spinner",
  background: "rgba(0, 0, 0, 0.7)",
};

export const resultStatusMap = {
  0: {
    text: "处理中",
    class: "wating",
    noIcon: true,
  },
  1: {
    text: "成功",
    class: "success",
    noIcon: true,
  },
  2: {
    text: "失败",
    class: "fail",
    noIcon: true,
  },
  3: {
    text: "过期",
    class: "fail",
    noIcon: true,
  },
  4: {
    text: "交易关闭",
    class: "fail",
    noIcon: true,
  }
};

export const realNameAuthMap = {
  0: {
    text: "未认证",
  },
  1: {
    text: "认证成功",
  },
  2: {
    text: "认证失败",
  },
};

export const enterpriseAuthMap = {
  0: {
    text: "未认证",
  },
  1: {
    text: "待审核",
  },
  2: {
    text: "已认证",
  },
  3: {
    text: "认证失败",
  },
};

export const SINGLE_MENUS = {
  account: {
    0: "Account",
    1: "ModifyPassword",
  },
  wallet: {
    0: "FuelManage",
    1: "OrderMan",
    2: "Question",
  },
  platform: {
    0: "ReportProblem",
  },
};

export const MULT_MENUS = {
  "1-1": "Index",
  "2-1": "ServiceOverview",
  "2-2": "IntegratedQuery",
  "3-1": "",
  "3-2": "",
};

export const NO_LOGIN_ROUTENAME = ["Login", "Register", "ResetPassword", "Agreement"];

export const AXIS_HOURS = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export const NOTICE_TYPE = {
  0: "短信",
  1: "邮件"
}

import { accountInfo } from "@/api/interface/account.js";

export default {
  setUserInfo({ commit, state }, payload) {
    commit("setUserInfo", payload);
    payload && sessionStorage.setItem("userInfo", JSON.stringify(payload));
    accountInfo().then(data => {
      Object.assign(payload, data);
      commit("setUserInfo", payload);
      payload && sessionStorage.setItem("userInfo", JSON.stringify(payload));
    });
  },
};

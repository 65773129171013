import { userLogout } from "@/api/interface/login";
import { Message } from "element-ui";
import store from "@/store";
import dayjs from "dayjs";

/**
 * 深拷贝对象，可以正确序列化日期
 * @param {*} obj
 */
export const DEEP_CLONE = function (obj) {
  const objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // 判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          // 深拷贝日期类型
          if (obj[key] instanceof Date) {
            objClone[key] = new Date(obj[key].valueOf());
          } else {
            objClone[key] = DEEP_CLONE(obj[key]);
          }
        } else {
          // 如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
};

/**
 * 数字添加千分位符号
 */
export const ADD_THOUSANDS = function (num, bool, digit = 2) {
  if (num) {
    const res = bool
      ? String(num).replace(/(\d)(?=(?:\d{3})+$)/g, "$1,")
      : num.toFixed(digit).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    return res;
  } else {
    return "--";
  }
};

export const DATE_FORMAT = function (date, Str) {
  const newDate = new Date(date);
  const Y = newDate.getFullYear();
  const M =
    newDate.getMonth() + 1 < 10
      ? "0" + (newDate.getMonth() + 1)
      : newDate.getMonth() + 1;
  const D =
    newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
  const H =
    newDate.getHours() < 10 ? "0" + newDate.getHours() : newDate.getHours();
  const Mm =
    newDate.getMinutes() < 10
      ? "0" + newDate.getMinutes()
      : newDate.getMinutes();
  const S =
    newDate.getSeconds() < 10
      ? "0" + newDate.getSeconds()
      : newDate.getSeconds();
  const MS = newDate.getMilliseconds() ? newDate.getMilliseconds() : "000";
  const strType = {
    ym: Y + "-" + M,
    d: Y + "-" + M + "-" + D,
    s: Y + "-" + M + "-" + D + " " + H + ":" + Mm + ":" + S,
    dms: H + ":" + Mm + ":" + S,
    ms: Y + "-" + M + "-" + D + " " + H + ":" + Mm + ":" + S + " " + MS,
  };
  if (Str) {
    return strType[Str] || Y + "-" + M + "-" + D + " " + H + ":" + Mm + ":" + S;
  } else {
    return Y + "-" + M + "-" + D;
  }
};

// 防抖 节流
export function DEBOUNCE(fn, delay, immediate = true) {
  let timer = null;
  return function (...args) {
    if (timer) clearTimeout(timer);
    immediate && !timer && fn.apply(this, args);
    timer = setTimeout(() => {
      timer = null;
      !immediate && fn.apply(this, args);
    }, delay);
  };
}

export function THROTTLE(fn, delay, immediate = true) {
  let timer = null;
  return function (...args) {
    if (!timer) {
      timer = setTimeout(() => {
        timer = null;
        !immediate && fn.apply(this, args);
      }, delay);
      immediate && fn.apply(this, args);
    }
  };
}

export function blobToFile(dataurl, filename = "file") {
  return new File([dataurl], `${filename}.png`);
}

export function base64ToFile(dataurl, filename = "file") {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let suffix = mime.split("/")[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime,
  });
}

export const pxtorem = function (px) {
  if (/%/gi.test(px)) {
    return px;
  } else {
    return parseFloat(px) / 100 + "rem";
  }
};
/**
 *   len1是开头不需要变成星号的长度 n2是末尾不需要变成星号的长度，设置默认值为0，不传的时候就除了设置的开头几位剩余全部变成星号。
 */
export const resetPrivateStr = (str, len1, len2 = 0) => {
  let strLen = str.length;
  let starts = "";
  for (let i = 0; i < strLen - len1 - len2; i++) {
    starts += "*";
  }
  str = str.substr(0, len1) + starts + str.substr(strLen - len2);
  return str;
};

function getBase64Image(img) {
  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  const dataURL = canvas.toDataURL("image/png");
  return dataURL;
}

export function urlToBase64(imgUrl) {
  return new Promise((resolve, reject) => {
    if (!/^https?/.test(imgUrl)) {
      resolve(data);
      return;
    }
    const img = new Image();
    img.setAttribute("src", `${imgUrl}?v=${new Date().getTime()}`);
    img.setAttribute("crossOrigin", "Anonymous");
    img.onload = function () {
      const data = getBase64Image(img);
      resolve(data);
    };
  });
}

export function isScroll() {
  let screen_h = window.innerHeight;
  let body_h =
    document.body.scrollHeight || document.documentElement.scrollHeight;
  if (body_h > screen_h) {
    return true;
  } else {
    return false;
  }
}

export function comLoginOut(code) {
  window.localStorage.clear();
  window.sessionStorage.clear();

  if (code == 703 || code == 705) {
    window.location.replace("/login");
  } else if (code == 706) {
    window.location.replace("/reset-password?pass=forget");
  } else if (code == 707) {
    window.location.replace("/register");
  } else {
    userLogout().then(() => {
      Message.success("退出成功！");
      window.location.replace("/login");
    });
  }
}

export function formatNumber(number) {
  if (number <= 0 || isNaN(Number(number))) {
    return 0;
  }
  return Number(number).toLocaleString();
}

export function xAisFromat(val, type) {
  if (type == "time" && val) {
    val = val < 10 ? `0${val}:00` : `${val}:00`;
  }
  return val;
}

export function lastDayRange(val, days) {
  if (!days) {
    return val;
  }
  let arr = [];
  for (let i = days - 1; i > 0; i--) {
    arr.push(dayjs(val).subtract(i, "day").format("MM-DD"));
  }
  arr.push(dayjs(val).format("MM-DD"));
  return arr;
}

// 两数字相乘
export function accMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

export function setVersionConsole() {
  const pkgInfo = require("../../../../version-info.json");
  console.log(`%cversion: ${pkgInfo.version}`, 'color: rgb(30,152,255);padding:6px;');
}

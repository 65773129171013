<template>
  <div
    id="app"
    class="com-min-width"
  >
    <router-view />
  </div>
</template>

<script>
import { setVersionConsole } from "@/utils/common.js";
import { mapMutations, mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      
    };
  },
  computed: {},
  methods: {
    ...mapActions(["setUserInfo"]),
    ...mapMutations(["setDeviceInfo"]),
    initUserInfo() {
      const userInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
      if (Object.keys(userInfo).length > 0) {
        this.setUserInfo(userInfo);
      }
    },
    initDeviceInfo() {
      const pkgInfo = require("../package.json");
      this.setDeviceInfo({
        baseUrl: `${location.protocol}//${process.env.VUE_APP_BASE_URL}`,
        blockUrl: `${process.env.VUE_APP_BLOCK_URL}`,
        proTitle: pkgInfo.proTitle,
      });
    },
  },
  created() {
    this.initUserInfo();
    this.initDeviceInfo();
  },
  mounted() {
    setVersionConsole();
  }
};
</script>

<style lang="scss">
@import "@/scss/_common.scss";
@import "@/scss/_icons.scss";
@import "@/scss/_button.scss";
@import "@/scss/_swiper.scss";
@import "@/scss/_title.scss";
@import "@/scss/el/_confirm.scss";
</style>

import * as API from "@/api/request";

const comUrl = "/v1/workbench/account/";

// 账号详情
export const accountInfo = () => {
  return API.get(`${comUrl}user/userInfo`);
};

// 修改账户信息
export const editAccountInfo = (data) => {
  return API.post(`${comUrl}user/changeInfo`, data);
};

// 变更联系人
export const changeContact = (data) => {
  return API.post(`${comUrl}identification/changeContact`, data);
};

// 站内信
export function msgList(params) {
  return API.post(`${comUrl}letter/list`, params);
}

export function msgRead(id) {
  return API.post(`${comUrl}letter/read/?id=${id}`);
}

export function msgNewNum() {
  return API.post(`${comUrl}letter/unReadNum`);
}

// 实名认证信息
export const realNameInfo = () => {
  return API.get(`${comUrl}identification/realName/select`);
};

// 企业机构认证信息
export const enterpriseInfo = () => {
  return API.get(`${comUrl}identification/shop/audit/info`);
};

// 获取实名认证剩余次数
export const verifyNumber = () => {
  return API.get(`${comUrl}identification/realName/availableTimes`);
};

// 提交实名信息验证
export const submitRealNameInfo = (data) => {
  return API.post(`${comUrl}identification/realName/audit`, data);
};

// 上传头像文件
export const uploadAvatarFile = (data) => {
  return API.upload(`${comUrl}user/uploadImg`, data);
};

// 上传文件
export const uploadFile = (data) => {
  return API.upload(`${comUrl}identification/file/upload`, data);
};

// 提交企业机构信息验证
export const submitEnterpriseInfo = (data) => {
  return API.post(`${comUrl}identification/shop/audit`, data);
};

// 获取企业机构信息验证剩余次数
export const verifyEnterpriseNumber = (data) => {
  return API.get(`${comUrl}identification/shop/available-times`, data);
};

export const updateIPwhite = (data) => {
  return API.post(`${comUrl}ip-white`, data);
};

export function ipWhiteList() {
  return API.get(`${comUrl}ip-white-list`);
}

import * as API from "@/api/request";

const comUrl = "/v1/workbench/account/";

// 登录 注册 认证流程
export function userLogin(data) {
  return API.post(`${comUrl}login`, data);
}

export function userLogout(data) {
  return API.post(`${comUrl}logout`, data);
}

// 忘记密码
export function forgetUpdatePassword(data) {
  return API.post(`${comUrl}forgetPwd`, data);
}

// 修改密码
export function updatePassword(data) {
  return API.post(`${comUrl}user/changePwd`, data);
}

// 注册
export function artRegister(data) {
  return API.post(`${comUrl}register`, data);
}

const codeUrl = "/v1/workbench/common/sms/";
// 注册短信
export function sendMessRegister(params) {
  params.type = 0;
  return API.post(`${codeUrl}code`, params);
}
// 修改密码 短信
export function sendMessUpdatePass() {
  return API.get(`${codeUrl}modify`);
}
// 忘记密码 短信
export function sendMessResetPass(params) {
  params.type = 1;
  return API.post(`${codeUrl}code`, params);
}

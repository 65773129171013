export default {
  deviceInfo: {
    fontSize: 0,
    baseUrl: "",
    blockUrl: "",
    proTitle: "",
  },
  userInfo:{
    authorization:''
  },
  asideMenus: {
    curSingleIndex: 0,
    curMultIndex: '1-1',
    menuType: 'index',
    singleMenu: false
  },
  gasInfo: {},
  queryInfo: {
    integratedKeyword: ''
  }
};
